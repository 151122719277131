
import { checkIsMobile } from '@/utils/device'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ReferrerRuleDialog extends Vue {
  @Prop({ type: Number, default: 0 })
  ratio!:number

  @Prop({ type: Number, default: 0 })
  count!:number

  private visible = false

  private get width () {
    return checkIsMobile() ? '90vw' : '500px'
  }

  close () {
    this.visible = false
  }

  open () {
    this.visible = true
  }
}
