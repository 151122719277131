import api from '@/config/api'
import fetch from '@/utils/fetch'

// ts ignore
export interface ReferrerProfit {
  glmr: number,
  bnb: number,
  matic: number,
  // eslint-disable-next-line camelcase
  usdt_bsc: number,
  // eslint-disable-next-line camelcase
  usdt_polygon: number,
  ratio: number,
  count: number
}

export async function getProfit (): Promise<ReferrerProfit> {
  const res = await fetch.post(`${api}/pns_api/domains/balance`, {})
  if (res.data.result !== 'ok') {
    throw new Error(res.data.message || 'Get profit fail')
  }

  return {
    glmr: res.data.glmr_balance,
    bnb: res.data.bnb_balance,
    matic: res.data.matic_balance,
    usdt_bsc: res.data.usdt_bsc_balance,
    usdt_polygon: res.data.usdt_polygon_balance,
    ratio: res.data.percent,
    count: res.data.invite_count
  }
}

export async function withdraw (address: string, amount: number, token: string): Promise<void> {
  const res = await fetch.post(`${api}/pns_api/domains/withdraw`, { amount: amount, token: token.toLowerCase() })
  if (res.data.result !== 'ok') {
    throw new Error(res.data.message || 'Withdraw fail')
  }
}

export async function submitReferrer (referrerDomain: string, registDomain: string, txHash: string, symbol: string): Promise<void> {
  const res = await fetch.post(`${api}/pns_api/domains/create_invite`, {
    invite_domain: referrerDomain.toLowerCase(),
    be_invited_domain: registDomain.toLowerCase(),
    tx_hash: txHash,
    token: symbol.toLowerCase()
  })
  if (res.data.result !== 'ok') {
    throw new Error(res.data.message || 'Withdraw fail')
  }
}

export interface WithdrawRecord {
  address: string;
  amount: string;
  balance: string;
  createTime: string;
  state: boolean;
  token: string
}
export async function getWithdrawRecords ():Promise<WithdrawRecord[]> {
  const res = await fetch.post(`${api}/pns_api/domains/withdraw_logs`, {})
  if (res.data.result !== 'ok') {
    throw new Error(res.data.message || 'Get withdraw records fail')
  }

  return res.data.withdraw_logs.map((item: any) => {
    return {
      address: item.address,
      amount: item.amount,
      balance: item.balance,
      createTime: item.created_at,
      state: item.status,
      token: item.token
    }
  })
}

export interface ReferrerRecord {
  registrant: string;
  amount: string;
  balance: string;
  createTime: string;
  domain: string;
  token: string;
}

export async function getReferrerRecords (): Promise<ReferrerRecord[]> {
  const res = await fetch.post(`${api}/pns_api/domains/invite_logs`, {})
  if (res.data.result !== 'ok') {
    throw new Error(res.data.message || 'Get referrer records fail')
  }

  return res.data.invite_logs.map((item: any) => {
    return {
      registrant: item.register_address,
      domain: item.be_invited_domain,
      amount: item.amount,
      createTime: item.created_at,
      balance: item.balance,
      token: item.token
    }
  })
}
