
import { shortAddr } from '@/utils/content'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { lang } from '@/lang'
import { getReferrerRecords, getWithdrawRecords, WithdrawRecord, ReferrerRecord } from '@/sdk/referrer'

@Component({
  components: {}
})
export default class ReferrerRecords extends Vue {
  @Prop({ type: Boolean, default: false })
  private value!: boolean

  private loading = true

  private get text () {
    return {
      tabReferral: lang('REFERRER_PAGE_RECORD_TAB_REFERRAL'),
      tabWithdrawals: lang('REFERRER_PAGE_RECORD_TAB_WITHDRAW'),
      listRegistrant: lang('REFERRER_PAGE_RECORD_LIST_REGISTRANT'),
      listDomain: lang('REFERRER_PAGE_RECORD_LIST_DOMAIN'),
      listTime: lang('REFERRER_PAGE_RECORD_LIST_TIME'),
      listAmount: lang('REFERRER_PAGE_RECORD_LIST_AMOUNT'),
      listBalance: lang('REFERRER_PAGE_RECORD_LIST_BALANCE'),
      listState: lang('REFERRER_PAGE_RECORD_LIST_STATE'),
      listAddress: lang('REFERRER_PAGE_RECORD_LIST_ADDRESS'),
      reward: lang('REFERRER_PAGE_RECORD_LIST_REWARD')
    }
  }

  private withdrawRecord: WithdrawRecord[] = []
  private referralRecord: ReferrerRecord[] = []

  private tab : 'withdraw' | 'referral' = 'referral'

  private shortAddress (str: string) {
    return shortAddr(str)
  }

  private shortDomain (str: string) {
    const a = str.replace('.dot', '')
    if (a.length < 6) return a

    return a.substr(0, 4) + '...' + a.substr(-1) + '.dot'
  }

  private formatNum (str: string) {
    return Number(str).toFixed(4)
  }

  private formatSymbol (str: string) {
    if (!str) return 'GLMR'
    return str.toUpperCase().split('_')[0]
  }

  private async getRecord () {
    this.loading = true
    try {
      this.withdrawRecord = await getWithdrawRecords()
      this.referralRecord = await getReferrerRecords()
    } finally {
      this.loading = false
    }
  }

  created () {
    this.getRecord()
  }
}
