
import { Vue, Component, Ref } from 'vue-property-decorator'
import { icons } from '@/config/networks'
import { wallet } from '@/utils/wallet'
import { ensureSetup } from '@/sdk/base'
import { getProfit, ReferrerProfit, withdraw } from '@/sdk/referrer'
import { errorMessage, successMessage } from '@/utils/message'
import { lang } from '@/lang'
import ReferrerRuleDialog from './ReferrerRuleDialog.vue'
import ReferrerRecord from './ReferrerRecord.vue'
import { copyText } from '@/utils/content'
import { checkEthAddress, getDomains } from '@/sdk/domain'
import paymentToken from '@/config/paymentToken'

@Component({
  components: {
    referrerRuleDialog: ReferrerRuleDialog,
    referrerRecord: ReferrerRecord
  }
})
export default class Referrer extends Vue {
  private get text () {
    return {
      title: lang('REFERRER_PAGE_TITLE'),
      rule: lang('REFERRER_PAGE_RULE'),
      labelAddress: lang('REFERRER_PAGE_LABEL_ADDRESS'),
      labelReferrals: lang('REFERRER_PAGE_LABEL_LINK'),
      labelCurrency: lang('REFERRER_PAGE_LABEL_CURRENCY'),
      labelAmount: lang('REFERRER_PAGE_LABEL_AMOUNT'),
      labelRatio: lang('REFERRER_PAGE_LABEL_RATIO').replace('#', `<span>${this.ratio}%</span>`),
      withdrawBtn: lang('REFERRER_PAGE_LABEL_WITHDRAW_BTN')
    }
  }

  @Ref() private ruleDialog!:ReferrerRuleDialog

  private get paymentToken () {
    return paymentToken
  }

  private loading = true
  private withdrawing = false

  private profit:ReferrerProfit | null = {
    glmr: 0,
    bnb: 0,
    matic: 0,
    usdt_bsc: 0,
    usdt_polygon: 0,
    ratio: 0,
    count: 0
  }

  private referralLink = ''
  private address = ''
  private withdrawAmount = ''
  private ratio = 0
  private count = 0
  private selectedToken: Common.MenuItem<Common.Currentcy> | null = null
  private tokenIcon = ''

  private showProfit (num: number) {
    return Number(num).toFixed(4)
  }

  private get icons () {
    return icons
  }

  mounted (): void {
    this.selectedToken = paymentToken[0]
    setTimeout(() => {
      this.getUserProfit()
    }, 100)
  }

  private async getUserProfit () {
    try {
      this.loading = true
      await ensureSetup(true)
      if (!wallet.accountId) {
        this.loading = false
        return
      }

      const profit = await getProfit()
      this.ratio = profit.ratio
      this.count = profit.count
      this.profit = profit
      this.withdrawAmount = '0'
      this.address = wallet.accountId
      const domain = await getDomains()
      if (domain.length) {
        this.referralLink = `${window.location.host}?referrer=` + domain[0].name
      }
    } finally {
      this.loading = false
    }
  }

  private async handelWithDraw () {
    if (!this.profit || !this.selectedToken) return
    const profit = Number((this.profit as any)[this.selectedToken.id.toLowerCase()])
    const withdrawAmount = Number(this.withdrawAmount.trim())
    if (Number(profit) === 0) {
      errorMessage('Insufficient balance')
      return
    }

    if (!withdrawAmount || Number(withdrawAmount) === 0 || withdrawAmount > profit) {
      errorMessage('Insufficient balance')
      return
    }

    if (withdrawAmount < 1) {
      errorMessage('The minimum withdrawal amount is 1')
      return
    }

    // 校验收款地址
    if (!checkEthAddress(this.address)) {
      errorMessage('Invalid receiver address')
      return
    }

    try {
      this.withdrawing = true
      await withdraw(this.address, Number(this.withdrawAmount), this.selectedToken.id)
      successMessage('Withdraw success')
      setTimeout(() => {
        this.getUserProfit()
      }, 2000)
    } catch (e) {
      errorMessage(e.message)
    } finally {
      this.withdrawing = false
    }
  }

  private onlyNumOnePoint () {
    let res = this.withdrawAmount
    res = res.replace(/[^\d.]/g, '')
    res = res.replace(/^\./g, '')
    res = res.replace(/\.{2,}/g, '.')
    res = res.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    this.withdrawAmount = res
  }

  private copy (str: string) {
    copyText(str)
  }

  private showRule () {
    this.ruleDialog.open()
  }

  private handlerSelectToken (item: any) {
    this.selectedToken = item
  }
}
